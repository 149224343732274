import axios from "axios"
import { getCurrentUser } from "../main.js"
import eventBus from "../framework/eventBus.js"
const apiUrl = process.env.VUE_APP_API_URL
const reportUrl = process.env.VUE_APP_REPORT_URL
console.log("API URL: " + apiUrl)
console.log("REPORT URL: " + reportUrl)

const client = axios.create({
  baseURL: apiUrl,
})

const reportClient = axios.create({
  baseURL: reportUrl,
})

client.interceptors.request.use(config => {
  eventBus.$emit("loading-start")
  return config
})

client.interceptors.response.use(
  response => {
    eventBus.$emit("loading-stop")
    return response
  },
  error => {
    eventBus.$emit("loading-stop")
    throw error
  },
)

reportClient.interceptors.request.use(config => {
  eventBus.$emit("loading-start")
  return config
})

reportClient.interceptors.response.use(
  response => {
    eventBus.$emit("loading-stop")
    return response
  },
  error => {
    eventBus.$emit("loading-stop")
    throw error
  },
)

async function getRequest(method, path, contentType = "application/json") {
  let request = {
    url: path,
    method: method,
    headers: {
      "Content-Type": contentType,
    },
  }
  const user = await getCurrentUser()
  let token = await user.getIdToken()
  request.headers["Authorization"] = `Bearer ${token}`

  return request
}

export async function get(path, params = null) {
  let request = await getRequest("get", path)
  if (params) {
    request.params = params
  }
  console.log("GET - " + reportClient.getUri())
  let response = await reportClient(request)
  return response ? response.data : null
}

export async function post(path, data) {
  let request = await getRequest("post", path)
  request.data = data
  console.log("POST - " + client.getUri())
  let response = await client(request)

  return response ? response.data : null
}

export async function put(path, data) {
  let request = await getRequest("put", path)
  request.data = data
  console.log("PUT - " + client.getUri())
  let response = await client(request)

  return response ? response.data : null
}

export async function deleteRequest(path, data) {
  let request = await getRequest("delete", path)
  request.data = data
  console.log("DELETE - " + client.getUri())
  let response = await client(request)

  return response ? response.data : null
}

export async function uploadFile(path, data) {
  let request = await getRequest("post", path, "multipart/form-data")
  let formData = new FormData()
  for (const key in data) {
    const dataValue = data[key]
    if (dataValue == null) {
      continue
    }
    formData.append(key, data[key])
  }
  request.data = formData
  console.log("UPLOAD - " + client.getUri())
  let response = await client(request)
  return response ? response.data : null
}

export async function download(path, data = null, params = null) {
  let request = await getRequest("get", path)

  if (data) {
    request = await getRequest("post", path)
    request.data = data
  } else if (params) {
    request.params = params
  }
  request.responseType = "blob"

  console.log("DOWNLOAD - " + reportClient.getUri())
  let response = await reportClient(request)
  let bytes = response ? response.data : null

  if (!bytes) {
    eventBus.$emit("error")
    return
  }

  let contentDisposition = response.headers["content-disposition"]

  let link = window.document.createElement("a")
  link.href = window.URL.createObjectURL(new Blob([bytes]))

  let filename = "download"

  if (contentDisposition && contentDisposition.includes("filename=")) {
    const regExpFilename = /filename="(?<filename>.*)"/
    filename = regExpFilename.exec(contentDisposition).groups.filename
  }

  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
